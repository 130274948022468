import render_internal from 'assets/img/interni-min.jpg';
import { useEffect, useMemo, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cartNextStep, cartSetRender } from 'store/actions/cart';
import { selectProducts, selectRender, selectRenderAmbients } from "store/selectors";
import { createUUID } from 'utils/helpers';

export default function SecondStep() {

    const dispatch = useDispatch();
    const [newAmbientSelect, setNewAmbientSelect] = useState();
    const render = useSelector(selectRender);
    const renderAmbients = useSelector(selectRenderAmbients);
    const avaiableAmbients = useMemo(() => renderAmbients.data.filter(a => a.type === 'OTHER' && !render.ambients.find(ra => ra.id === a.id)), [render]);
    const selectedAmbients = Object.values(
        render.ambients.filter(a => renderAmbients.object[a.id].type === 'OTHER')
        .reduce((acc, ambient) => ({
            ...acc,
            [ambient.id]: {
                ...ambient,
                qty: (acc[ambient.id] ? acc[ambient.id].qty : 0) + 1
            }
        }), {})
    );


    const selectAmbients = (id) => {
        return render.ambients.filter(a => a.id === id);
    }

    const handleRemoveAmbient = (ambient_id) => {
        let is_removed = false;
        dispatch(cartSetRender({
            ...render,
            ambients: render.ambients.filter(a => {
                if (!is_removed && a.id === ambient_id) {
                    is_removed = true;
                    return false;
                }
                return true;
            }).sort((a, b) => a.label > b.label)
        }));
    }

    const handleAddAmbient = (ambient_id) => {
        let remove_ambients = [];
        const is_living = renderAmbients.object[ambient_id].type == 'LIVING';
        if (is_living) {
            remove_ambients = render.ambients.filter(a => renderAmbients.object[a.id].type === 'LIVING').map(a => a.id);
        }
        dispatch(cartSetRender({
            ...render,
            ambients: [
                ...render.ambients,
                { id: +ambient_id, label: renderAmbients.object[ambient_id].label, uuid: createUUID() },
                ...renderAmbients.data.filter(a => a.select_with === ambient_id).map(a => ({ 
                    id: +a.id,
                    uuid: createUUID(),
                    label: renderAmbients.object[a.id].label,
                }))
            ]
            .filter(a => !remove_ambients.includes(a.id))
            .sort((a, b) => a.label > b.label)
        }));
    }


    const handleNextStep = () => {
        dispatch(cartNextStep());
    }

    useEffect(() => {
        setNewAmbientSelect();
    }, [avaiableAmbients]);

    return (<>
        <div className='position-relative'>
            <img src={render_internal} style={{ maxHeight: '150px', objectFit: 'cover' }} className="w-100" />
        </div>

        <div className='section pt-3 text-center'>
            <h2 className='mb-0'>Come è composto l’immobile?</h2>
            <div className="text mb-2">Seleziona la tipologia ed il numero di ambienti.</div>

            <div className='text-gray text-start'>
                <table className='w-100'>
                    <tbody>
                        <tr>
                            <td style={{ width: '110px' }}>Zona giorno</td>
                            <td><hr className='m-0' /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Se selezionano cucina e soggiorno, non ci sarà l’open space. Se selezionano l”open space”, cucina e soggiorno non ci saranno */}
            <ul className="listview no-after image-listview bg-transparent no-border p-0 mt-1 m-0">
                {renderAmbients.data.filter(a => a.type === 'LIVING' && !a.select_with).map((ambient) => {
                    const selected = selectAmbients(ambient.id);
                    return (
                        <li key={ambient.id}>
                            <div className="item p-0" style={{ minHeight: '33px' }}>
                                <div className="in">
                                    <div className='fw-600'>{ambient.description || ambient.label}</div>
                                    <div>
                                        <FormCheck
                                            id={`radio_${ambient.id}`}
                                            type="radio"
                                            label=""
                                            checked={selected.length > 0}
                                            onClick={() => handleAddAmbient(ambient.id)}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>

            <div className='text-gray text-start mt-2'>
                <table className='w-100'>
                    <tbody>
                        <tr>
                            <td style={{ width: '110px' }}>Altri ambienti</td>
                            <td>
                            <hr className='m-0' /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <ul className="listview no-after image-listview bg-transparent no-border p-0 mt-1 m-0">
                {selectedAmbients.map((ambient) => {
                        const is_disabled = renderAmbients.object[ambient.id].required && ambient.qty === 1;
                        return (
                            <li key={ambient.id}>
                                <div className="item p-0" style={{ minHeight: '33px' }}>
                                    <div className="in">
                                        <div className='fw-600'>{renderAmbients.object[ambient.id].label}</div>
                                        <div>
                                            <div className="stepper stepper-primary" style={{ height: '25px', width: '90px', minWidth: '90px', borderRadius: '6px', display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'space-between', overflow: 'hidden' }}>
                                                <a
                                                    disabled
                                                    className={`render-ambient stepper-button stepper-down ${is_disabled ? 'disabled' : ''}`}
                                                    onClick={() => is_disabled ? null : handleRemoveAmbient(ambient.id)}
                                                >-</a>
                                                <input 
                                                    value={ambient.qty}
                                                    type="text" 
                                                    readOnly
                                                    className="form-control text-white text-14" 
                                                    style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding: '0 3px', width: '33px', height: '25px' }}
                                                />
                                                <a 
                                                    className="render-ambient stepper-button stepper-up" 
                                                    onClick={() => handleAddAmbient(ambient.id)}
                                                >+</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
            </ul>

            {avaiableAmbients.length > 0 ? <div className="form-group boxed mt-2">
                <div className="input-wrapper d-flex">
                    <select 
                        className="form-control form-select" 
                        style={{ height: '36px', fontSize: '14px', borderRadius: '5px 0px 0px 5px' }}
                        onChange={(e) => setNewAmbientSelect(e.target.value)}
                        value={newAmbientSelect}
                    >
                        <option value="">Seleziona</option>
                        {avaiableAmbients.map((ambient) => (
                            <option key={ambient.id} value={ambient.id}>{ambient.label}</option>
                        ))}
                    </select>
                    <span 
                        className="cursor-pointer"
                        style={{ backgroundColor: '#135cfa', width: '40px', textAlign: 'center', fontSize: '25px', borderRadius: '0px 5px 5px 0px', lineHeight: '32px' }}
                        onClick={() => newAmbientSelect && handleAddAmbient(newAmbientSelect)}
                    >+</span>
                </div>
            </div> : ''}


            <div className="form-group mt-3">
                <button 
                    type="button" 
                    className={`btn-block btn btn-primary ${render.ambients.filter(a => renderAmbients.object[a.id].type === 'LIVING').length === 0 ? 'disabled' : ''}`}
                    onClick={handleNextStep}
                >Continua <IoChevronForwardOutline className="next-icon-btn" /></button>
            </div>
        </div>
    </>);
}
