import render_internal from 'assets/img/interni-min.jpg';
import { useMemo } from 'react';
import { IoCheckmark, IoChevronForwardOutline, IoClose, IoCreateOutline, IoPencilOutline, IoStar } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { cartAddProduct, cartEmpty, cartNextStep, cartSetData, cartSetRender } from 'store/actions/cart';
import { selectCart, selectProducts, selectRender, selectRenderAmbients } from 'store/selectors';
import OffcanvasCustomPackage from './OffcanvasCustomPackage';
import useOffCanvasReducer from 'hooks/useOffCanvasReducer';

export default function ThirdStep() {

    const [showCustomPackage, dispatchShowCustomPackage] = useOffCanvasReducer();
    const dispatch = useDispatch();
    const cart = useSelector(selectCart);
    const render = useSelector(selectRender);
    const products = useSelector(selectProducts);
    const renderAmbients = useSelector(selectRenderAmbients);
    const selectedProduct = useMemo(() => cart.products.length ? cart.products[0] : null, [cart.products]);
    const calculateProducts = useMemo(() => products.data.map(product => {
        const { price_matrix } = product;
        const living_ambients = render.ambients.filter(a => renderAmbients.object[a.id].type === 'LIVING');

        let expanded = [];
        for(let i = 0; i < render.ambients.length; i++) {
            const am = {...render.ambients[i]};
            const same_ids = expanded.filter(a => a.id === am.id);
            am.label = am.label;
            am.counter = (same_ids.length > 0 ? (same_ids.length + 1) : '')
            am.type = renderAmbients.object[am.id].type;
            am.required = renderAmbients.object[am.id].required;
            am.views = 0;
            am.order_by = renderAmbients.object[am.id].order_by;
            if (am.type === 'LIVING') {
                am.views = living_ambients.length === 1 ? price_matrix.views_x_living : 1;
            } else {
                if (same_ids.reduce((acc, a) => (acc + a.views), 0) < price_matrix[`view_x_${am.required ? 'required' : 'others'}_max`]) {
                    am.views = price_matrix[`view_x_${am.required ? 'required' : 'others'}`];
                }
            }

            expanded.push(am);
        }

        const grouped = Object.values(expanded.reduce((acc, a) => {
            acc[a.id] = acc[a.id] || {};
            acc[a.id].id = a.id;
            acc[a.id].label = a.label;
            acc[a.id].views = (acc[a.id].views || 0) + a.views;
            return acc;
        }, {}));

        
        const views_n = expanded.reduce((acc, v) => acc + v.views, 0);
        const ambient_n = expanded.filter(v => v.views > 0).length;

        const price = (ambient_n * price_matrix.price_x_ambient) + (views_n * price_matrix.price_x_view) +
            (+price_matrix.plan ? +price_matrix.plan_price : 0) + 
            (+price_matrix.virtual_tour ? +price_matrix.virtual_tour_price : 0);

        
        expanded.sort((a, b) => (a.order_by - b.order_by));


        return {
            ...product,
            grouped,
            expanded,
            views_n,
            price
        }
    }, [products, render, renderAmbients.object]));

    const onChangePack = (product) => {
        dispatch(cartEmpty());
        dispatch(cartAddProduct(product));
    }

    const handleNextStep = (customViews = {}, price = null) => {
        dispatch(cartSetData({
            ...cart,
            ...(price !== null && {
                products: [
                    {
                        ...cart.products[0], 
                        price: price
                    },
                ]
            }),
            metadata: {
                ...cart.metadata,
                render: {
                    ...render,
                    ambients: render.ambients.map(a => ({
                        ...a,
                        views: (customViews[a.uuid] ?? 0) + selectedProduct.expanded.find(am => a.uuid === am.uuid).views
                    })),
                    is_custom: price !== null
                }
            }
        }));
        dispatch(cartNextStep());
    }

    return (<>
        <div className='position-relative'>
            <img src={render_internal} style={{ maxHeight: '150px', objectFit: 'cover' }} className="w-100" />
        </div>

        <div className='pt-3 text-center'>
            <h2 className='mb-0'>Scegli un piano</h2>
            <div className="text mb-2">Seleziona il piano più appropriato per te.</div>

            <div className="section choose-credit credit-dark mb-1">
                {calculateProducts.map(product => (
                    <label className="credit-card" htmlFor={`pack_${product.id}`} key={`pack_${product.id}`}>
                        {selectedProduct?.id === product.id && <span 
                            className="text-active text-25 position-absolute cursor-pointer" 
                            style={{ left: 10, top: 10 }}
                            onClick={() => dispatchShowCustomPackage('OPEN')}
                        >
                            <IoCreateOutline />
                        </span>}
                        <input
                            type="radio"
                            name="credit"
                            className="radio"
                            id={`pack_${product.id}`}
                            checked={product.id === selectedProduct?.id}
                            onChange={() => onChangePack(product)}
                        />
                        <span className="plan-details">
                            <span className="plan-type text-uppercase">
                                {product.description == 'Consigliato' ? <IoStar className="vertical-align-n2 me-05"/> : ''}{product.description}
                            </span>
                            <span className="plan-cost">
                                {product.price}€ <span className="text-white fw-500 text-16">+IVA</span>
                            </span>
                            <span className="text-white fw-500 text-14">{(product.views_n + (product.price_matrix.plan ? 1 : 0) + (product.price_matrix.emotional_view))} VISTE INTERNE</span>
                            <span className="desc text-start">
                                <ul className='p-0 m-0 mt-1' style={{ listStyleType: 'none' }}>
                                    <li>
                                        <IoCheckmark className='text-20' /> {product.grouped.filter(a => a.views > 0).map(v => v.views + ' ' + v.label).join(', ')}      
                                    </li>
                                    <li className={`${product.price_matrix.plan ? '' : 'text-gray'}`}>
                                        {product.price_matrix.plan ? <IoCheckmark className='text-20' /> : <IoClose className='text-20' /> } Planimetria 3D
                                    </li>
                                    <li className={`${product.price_matrix.emotional_view ? '' : 'text-gray'}`}>
                                        {product.price_matrix.emotional_view ? <IoCheckmark className='text-20' /> : <IoClose className='text-20' /> } {product.price_matrix.emotional_view || ''} Viste emozionali {!product.price_matrix.emotional_view || '(Omaggio)'}
                                    </li>
                                    <li className={`${product.price_matrix.virtual_tour ? '' : 'text-gray'}`}>
                                        {product.price_matrix.virtual_tour ? <IoCheckmark className='text-20' /> : <IoClose className='text-20' /> } Virtual tour interattivo
                                    </li>
                                </ul>
                            </span>
                            <hr className='my-2' />
                            <span className="text-white fw-500 text-14">CONSEGNA 4 GIORNI LAVORATIVI</span>
                        </span>
                    </label>
                ))}

            </div>

            <div className="form-group mt-3 section">
                <button 
                    type="button" 
                    className={`btn-block btn btn-primary ${selectedProduct ? '' : 'disabled'}`}
                    onClick={handleNextStep}
                >Continua <IoChevronForwardOutline className="next-icon-btn" /></button>
            </div>
        </div>
        <OffcanvasCustomPackage 
            show={showCustomPackage} 
            handleClose={() => dispatchShowCustomPackage('CLOSE')}
            calculateProducts={calculateProducts}
            handleNextStep={handleNextStep}
        />
    </>);
}