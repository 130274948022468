//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoCheckmarkCircleSharp, IoHourglass, IoCloudUpload, IoCloseCircleSharp, IoBusiness, IoHome, IoCamera, IoPerson, IoArchive, IoSettings, IoSettingsOutline } from 'react-icons/io5';
import moment from 'moment';

//logic
import usePostProductionAdmin from './usePostProductionAdmin';
import { useFilterInput } from 'hooks/useFilterInput';
import { isMobile } from 'utils/helpers';
import OffCanvasManageEditor from './components/OffCanvasManageEditor';
import { AutocompleteOrganization } from 'components/autocomplete/Autocomplete';
import FilterBox from 'components/FilterBox';
import { build } from 'joi';
import { useNavigate } from 'react-router';
import PostProductionRow from './PostProductionRow';


function Table({
    tasks,
    staff,
    handleChange,
    handleOnSave,
    filterSearchArray,
    filterInputValue,
    handleFilterInputChange
}) {

    const filteredTasks = tasks && staff ? tasks.filter(filterSearchArray) : [];

    return (
        <div className="table-responsive text-center" style={{ borderRadius: '6px 6px 0 0' }}>

            <table className="table post-production-admin table-striped">
                <thead>
                    <tr>
                        <th colSpan="11">
                            <div className="form-group boxed custom py-0">
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filtra per cliente, proprietario, riferimento o indirizzo"
                                        value={filterInputValue}
                                        onChange={handleFilterInputChange}
                                    />
                                </div>
                            </div>
                        </th>
                    </tr>
                    {!isMobile() && (
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Fotografo</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Proprietario</th>
                            <th scope="col">Immobile</th>
                            <th scope="col">Servizio</th>
                            <th scope="col">Social?</th>
                            <th scope="col">Note/Matter</th>
                            <th scope="col">Quantità</th>
                            <th scope="col">Stato</th>
                            <th scope="col">Azioni</th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {!tasks && (
                        <tr>
                            <td colSpan="11" className="text-center py-5"><span className="spinner-border"></span></td>
                        </tr>
                    )}
                    {tasks && (
                        filteredTasks.length === 0 ? (
                            <tr>
                                <td colSpan="11" className="text-center py-5">Nessun risultato trovato.</td>
                            </tr>
                        ) : (
                            filteredTasks.map((task, index) => (
                                <PostProductionRow
                                    key={task.id}
                                    index={index}
                                    task={task}
                                    staff={staff}
                                    handleChange={handleChange}
                                    handleOnSave={handleOnSave}
                                />
                            ))
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default function PostproductionAdmin() {

    const {
        tab, setTab,
        filter,
        tasks,
        products,
        organizations,
        staff,
        handleChange,
        handleOnSave,
        handleChangeFilter,
        isLoaded,
        showOffCanvasManageEditor, dispatchManageEditor
    } = usePostProductionAdmin();

    const [
        filterInputValue,
        handleFilterInputChange,
        filterSearchArray
    ] = useFilterInput(task => {
        const { order_item } = task;
        const { order } = order_item;
        const { organization } = order
        const { appointment } = order || {};
        const { building } = appointment || {};
        return `${organization.display_name}${building?.owner_first_name}${building?.owner_last_name}${building?.reference}${building?.address.full}`;
    });

    return (
        <>
            <Header 
                pageTitle={"Post-produzione"}
                RightComponent={() => (
                    <a className='headerButton'>
                      <IoSettingsOutline 
                        className='text-light'
                        onClick={() => dispatchManageEditor(true)}
                      />
                    </a>
                  )}
            />
            <Capsule className='pb-0'>
                <FilterBox
                    products={products}
                    organizations={organizations}
                    staff={staff}
                    filter={filter}
                    handleChangeFilter={handleChangeFilter}
                />

                <div className="section post-prod mb-2">
                    <Tabs
                        activeKey={tab}
                        onSelect={tab => setTab(tab)}
                        className="mb-2 capsuled"
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        <Tab
                            eventKey="WAITING_PHOTOGRAPHER"
                            title={<span><IoCloudUpload />Da caricare</span>}
                        >
                        </Tab>
                        <Tab
                            eventKey="WAITING_EDITOR"
                            title={<span><IoHourglass />In Post Produzione</span>}
                        >
                        </Tab>
                        <Tab
                            eventKey="DONE"
                            title={<span><IoCheckmarkCircleSharp />Completati</span>}
                        >
                        </Tab>
                    </Tabs>
                    <Table
                        tasks={tasks}
                        staff={staff}
                        handleChange={handleChange}
                        handleOnSave={handleOnSave}
                        filterSearchArray={filterSearchArray}
                        filterInputValue={filterInputValue}
                        handleFilterInputChange={handleFilterInputChange}
                    />
                </div>
            </Capsule>
            <OffCanvasManageEditor 
                show={showOffCanvasManageEditor}
                editors={staff?.e}
                handleClose={() => dispatchManageEditor('CLOSE')}
            />
        </>

    );
}