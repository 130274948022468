import useLocalStorage from 'hooks/useLocalStorage';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';

function ServiceCards(props) {

    const [experimentalFeature] = useLocalStorage('experimental_feature');
    //const [isDebugActive] = useLocalStorage('is_debug_active');
    const navigate = useNavigate();

    return (<>
        <div className="section mt-2 mb-2">
            <h2 className="mb-2">Altri servizi</h2>
            <div className="row">
                <div className="col-md-6 col-12">
                    <div className={`card text-white large-service-card mb-2`} onClick={() => navigate('/plan')} style={{ overflow: 'visible'}}>
                        <div className={`icon-box`} style={{ position: 'absolute', right: '5px', top: '-12px'}}>
                            <span className="badge badge-new badge-primary fw-bold big-badge text-uppercase">Novità</span>
                        </div>
                        <div className="service-image">
                            <img src="https://photodaf.com/wp-content/uploads/2022/07/planimetrie3d-1024x937.jpg" />
                        </div>
                        <div className="card-body card-service mt-2">
                            <h5 className="card-title">Planimetria 2D/3D</h5>
                            <p className="card-text">Immagina subito la disposizione degli spazi dell'immobile. </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12">
                    <div className={`card text-white large-service-card mb-2`} onClick={() => navigate('/render')} style={{ overflow: 'visible'}}>
                        <div className={`icon-box`} style={{ position: 'absolute', right: '5px', top: '-12px'}}>
                            <span className="badge badge-new badge-primary fw-bold big-badge text-uppercase">Novità</span>
                        </div>
                        <div className='service-image'>
                            <img src="https://photodaf.com/wp-content/uploads/2022/03/RENDER-1024x683.jpg" />
                        </div>
                        <div className="card-body card-service">
                            <h5 className="card-title">Rendering</h5>
                            <p className="card-text">Realizza ciò che ancora non esiste per attirare potenziali acquirenti.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);

}

export default ServiceCards;